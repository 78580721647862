<template>
  <div>
    <div class="row">
      <div class="col-xs-12">
        <ul class="breadcrumb">
          <li>Stock</li>
          <li class="active">
            <cte-stock-view
              :refresh-url="urls.changeView"
              :warehouses="warehouses"
              current="stock"
              :disabled="selecting"
            />
            <span class="badge">{{ nbElements }}</span>
          </li>

          <li class="pull-right shift">
            <div
              v-if="!selecting"
              class="btn-group"
            >
              <a
                class="btn btn-primary btn-sm"
                :href="urls.in"
              >
                <i class="fa fa-plus" />
                <span class="hidden-xs">Entrée de stock</span>
              </a>
              <a
                class="btn btn-primary btn-sm"
                href="#"
                :class="{'disabled': nbElements <= 0}"
                @click.prevent="select()"
              >
                <i class="fa fa-minus" />
                <span class="hidden-xs">Sortie de stock</span>
              </a>
            </div>
            <template v-else>
              <a
                class="btn btn-sm"
                href="#"
                @click.prevent="cancelOut()"
              >
                <i class="fa fa-times" />
                <span class="hidden-xs">Annuler</span>
              </a>
              <a
                class="btn btn-primary btn-sm"
                href="#"
                :class="{'disabled': selected.length <= 0}"
                @click.prevent="confirmOut()"
              >
                <i class="fa fa-check" />
                <span class="hidden-xs">Valider</span>
              </a>
            </template>
          </li>

          <li class="pull-right hidden-xs ">
            <a
              class="btn btn-primary btn-sm"
              :href="urls.icpe"
            >
              ICPE
            </a>
          </li>
        </ul>
      </div>
    </div>

    <cte-alert
      v-if="nbElements <= 0"
      type="info"
      msg="Aucun stock."
    />
    <template v-else>
      <div
        v-for="stock in nonEmptyStocks"
        class="cte-stock"
        :class="{'cte-stock--vehicle': stock.type !== 'warehouse'}"
      >
        <div class="cte-stock__header">
          <h2 class="cte-stock__header__title">
            {{ stock.type === 'warehouse' ? 'Stock' : 'Cargaisons' }}
            <br>{{ stock.title }}
          </h2>
          <div class="cte-stock__header__icon">
            <i
              class="fa"
              :class="stock.type === 'warehouse' ? 'fa-building' : 'fa-truck'"
            />
          </div>
        </div>
        <div class="cte-stock__body">
          <cte-alert
            v-if="stock.type === 'warehouse' && selecting"
            type="info"
            class="cte-stock__body__alert"
            msg="Cliquer ci-dessous sur le ou les blocs à faire sortir."
          />
          <div class="row">
            <div
              v-for="division in stock.divisions"
              class="col-xs-12"
              :class="'col-md-' + nbCols(stock)"
            >
              <div
                class="cte-stock__division"
                :class="{'cte-stock__division--last': isLastDivision(stock, division)}"
              >
                <div class="cte-stock__division__header">
                  <h3 class="cte-stock__division__header__title">
                    {{ division.title }}
                  </h3>
                  <h4
                    v-if="division.subtitle"
                    class="cte-stock__division__header__subtitle"
                  >
                    {{ division.subtitle }}
                  </h4>
                </div>

                <div class="cte-stock__division__body">
                  <div
                    v-for="block in division.blocks"
                    class="cte-stock__block"
                    :class="{
                      'cte-stock__block--selectable': stock.type === 'warehouse' && selecting,
                      'cte-stock__block--selected': isBlockSelected(block)}"
                    @click="toggleSelectBlock(stock, block)"
                  >
                    <div
                      v-for="packaging in block.packagings"
                      class="cte-stock__block__label"
                    >
                      x{{ packaging.quantity }}
                      <template v-if="packaging.packaging">
                        {{ packaging.packaging }} |
                      </template>
                      {{ block.label }}
                    </div>
                    <div class="cte-stock__block__workplace">
                      {{ block.businessNumber }}
                      {{ block.businessWorkPlace }}
                      <template v-if="block.outProducerPlace">
                        <template v-if="block.businessNumber">
                          |
                        </template>
                        Chargé le
                        {{ block.outProducerAt }}
                        à
                        {{ block.outProducerPlace }}
                      </template>
                    </div>
                    <div
                      v-if="block.wasteTrackerNumber"
                      class="cte-stock__block__wastetracker"
                    >
                      BSD {{ block.wasteTrackerNumber }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </template>

    <form
      ref="outForm"
      class="hide"
      method="post"
      :action="urls.out"
    >
      <input
        type="hidden"
        name="ids"
        :value="selected.join(',').split(',').join(',')"
      >
    </form>
  </div>
</template>

<script>
import CteStockView from './CteStockView.vue';
import CteAlert from '../../components/CteAlert.vue';

export default {
    components: {CteAlert, CteStockView},
    props: {
        urls: {type: Object, required: true},
        stocks: {type: Array, required: true},
        warehouses: {type: Array, required: true},
    },
    data() {
        return {
            selecting: false,
            selected: []
        };
    },
    computed: {
        nbElements() {
            return this.stocks.map(stock => stock.divisions).flat(1)
                .map(division => division.blocks.map(block => block.ids.length).flat(1)).flat(1)
                .reduce((a, b) => a + b, 0);
        },
        nonEmptyStocks() {
            return this.stocks.filter(stock => stock.divisions.length > 0);
        }
    },
    methods: {
        isLastDivision(stock, division) {
            return stock.divisions.indexOf(division) === stock.divisions.length - 1;
        },
        nbCols(stock) {
            const nbElements = stock.divisions.length;
            if (nbElements <= 2) {
                return 6;
            }

            if (nbElements === 3) {
                return 4;
            }

            if (nbElements === 4) {
                return 3;
            }

            if (nbElements <= 6) {
                return 2;
            }

            return 1;
        },
        blockId(block) {
            return block.ids.join(',');
        },
        blockSelectionIndex(block) {
            return this.selected.indexOf(this.blockId(block));
        },
        isBlockSelected(block) {
            return this.selected.indexOf(this.blockId(block)) !== -1;
        },
        select() {
            this.selecting = true;
        },
        confirmOut() {
            if (!this.selecting || this.selected.length <= 0) {
                return;
            }

            // console.log(this.selected.join(',').split(','));
            this.$refs.outForm.submit();
        },
        cancelOut() {
            this.selecting = false;
            this.selected = [];
        },
        toggleSelectBlock(stock, block) {
            if (stock.type !== 'warehouse' || !this.selecting) {
                return;
            }

            if (!this.isBlockSelected(block)) {
                this.selected.push(this.blockId(block));
            } else {
                this.selected.splice(this.blockSelectionIndex(block), 1);
            }
        }
    }
};
</script>

<style lang="scss">
.cte-stock {
    display: flex;
    flex-direction: row;
    margin-bottom: 1rem;
    @media (max-width: 992px) {
        & {
            flex-direction: column;
        }
    }

    &--vehicle {
        padding-bottom: 1rem;
        border-bottom: 1px solid black;
    }

    &__header {
        background-color: #f8f8f8;
        width: 150px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding: 1rem;

        @media (max-width: 992px) {
            & {
                width: 100%;
                margin-bottom: 1rem;
            }
        }

        &__title {
            text-align: center;
            font-size: 1.5rem;
            line-height: 1.5;
            margin: 0;
        }

        &__icon {
            font-size: 24px;
        }
    }

    &__body {
        flex: 1;

        &__alert {
            margin: 0 -8px;
        }
    }

    &__division {
        border-right: 1px solid #777777;
        display: flex;
        flex-direction: column;
        padding: 0 .5rem;

        &--last {
            border: none;
        }

        @media (max-width: 992px) {
            & {
                border: none;
            }
        }

        &__header {
            background-color: #f8f8f8;
            text-align: center;

            &__title {
                margin: .5rem;
                font-size: 1.5rem;
            }

            &__subtitle {
                margin: .5rem;
                font-size: 1rem;
                color: #777777;
            }
        }
    }

    &__block {
        margin: 1rem;
        padding: 1rem;
        border: 1px solid;
        border-radius: 1rem;

        &--selectable:hover, &--selected {
            background: lightgray;
            cursor: pointer;
        }

        &__workplace {
            margin-top: 0.5rem;
        }

        &__workplace, &__wastetracker {
            font-size: .8rem;
        }
    }
}
</style>
