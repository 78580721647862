<template>
  <div
    ref="mapContainer"
    class="cte-map"
    :class="{'cte-map--loading': loading}"
  />
</template>

<script>
import {icon, LatLng, Map, Marker, TileLayer} from 'leaflet';
import 'leaflet/dist/images/marker-shadow.png';
import 'leaflet/dist/images/marker-icon.png';
import 'leaflet/dist/images/marker-icon-2x.png';

const ZOOM_DEFAULT = 7;
const ZOOM_POSITION = 16;
const DEFAULT_CENTER = {latitude: 49.3206825, longitude: 2.5060481}; //CTE PLUS approximation

export default {
    props: {
        modelValue: {default: null},
        editable: {type: Boolean, default: false}
    },
    data() {
        return {
            loading: this.editable,
            markerPosition: null,
            map: null,
            editableMarker: null,
        };
    },
    watch: {
        markerPosition(newVal) {
            this.$emit('update:modelValue', newVal);
        }
    },
    mounted() {
        this.map = new Map(this.$refs.mapContainer);

        new TileLayer(
            'https://{s}.tile.osm.org/{z}/{x}/{y}.png',
            {attribution: '&copy; <a href="https://osm.org/copyright">OpenStreetMap</a> contributors'}
        )
            .addTo(this.map);

        this.initMarker();
    },
    beforeUnmount() {
        this.map.remove();
        this.map = null;
        this._placesLayer.remove();
        this._placesLayer = null;
        if (this.editableMarker) {
            this.editableMarker.remove();
            this.editableMarker = null;
        }
    },
    methods: {
        initMarker() {
            const onFound = position => {
                this.markerPosition = position;

                this.map.setView(
                    new LatLng(this.markerPosition.latitude, this.markerPosition.longitude),
                    this.isPosEqual(this.markerPosition, DEFAULT_CENTER) ? ZOOM_DEFAULT : ZOOM_POSITION
                );

                this.editableMarker = this._getMarker(this.markerPosition).addTo(this.map);
                this.editableMarker.on('moveend', () => this.markerPosition = this.editableMarker.getLatLng());

                this.loading = false;
            };

            if (this.isPosValid(this.modelValue)) {
                onFound(this.modelValue);
                return;
            }

            if (!this.editable) {
                this.map.setView(new LatLng(DEFAULT_CENTER.latitude, DEFAULT_CENTER.longitude), ZOOM_DEFAULT);
                return;
            }

            if ('geolocation' in navigator) {
                navigator.geolocation.getCurrentPosition(
                    position => {
                        onFound({latitude: position.coords.latitude, longitude: position.coords.longitude});
                    },
                    err => {
                        console.log('Geolocation error');
                        console.log(err);
                        onFound(DEFAULT_CENTER);
                    },
                    {
                        maximumAge: 10000,
                        timeout: 5000,
                        enableHighAccuracy: true
                    }
                );
                return;
            }

            onFound(DEFAULT_CENTER);
        },
        isPosValid(position) {
            return position && position.latitude;
        },
        isPosEqual(pos1, pos2) {
            return pos1.latitude === pos2.latitude && pos1.longitude === pos2.longitude;
        },
        _getMarker(position) {
            const iconRetinaUrl = '/compiled/images/marker-icon-2x.680f69f3.png';
            const iconUrl = '/compiled/images/marker-icon.2b3e1faf.png';
            const shadowUrl = '/compiled/images/marker-shadow.a0c6cc14.png';

            return new Marker(new LatLng(position.latitude, position.longitude), {
                draggable: !!this.editable, icon: icon({
                    iconRetinaUrl,
                    iconUrl,
                    shadowUrl,
                    iconSize: [25, 41],
                    iconAnchor: [12, 41],
                    popupAnchor: [1, -34],
                    tooltipAnchor: [16, -28],
                    shadowSize: [41, 41]
                })
            });
        }
    }
};
</script>

<style lang="scss">
@import '~leaflet';

.cte-map {
    height: 300px;
    width: 100%;
    overflow: hidden;
    z-index: 0;

    &--loading {
        animation: flickerAnimation 1s infinite;
    }
}

@keyframes flickerAnimation {
    0% {
        opacity: 1;
    }
    50% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}
</style>

