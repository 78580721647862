<template>
  <div class="cte-stock-deliveries">
    <div class="row">
      <div class="col-xs-12">
        <ul class="breadcrumb">
          <li>Stock</li>
          <li class="active">
            <cte-stock-view
              :refresh-url="urls.changeView"
              :warehouses="warehouses"
              current="deliveries"
            />
            <span class="badge">{{ displayed.length }}</span>
          </li>

          <li class="pull-right">
            <cte-selectable-statuses
              :current="deliveries.filters.statuses"
              :all="deliveries.filters"
              :available="['waiting', 'ack6m', 'ack6p']"
            />
          </li>
        </ul>
      </div>
    </div>

    <cte-alert
      v-if="displayed.length <= 0"
      type="info"
      msg="Aucune livraison."
    />
    <template v-else>
      <div class="row">
        <div class="col-xs-12">
          <div class="table-responsive">
            <table class="table table-condensed">
              <thead>
                <tr class="metahead">
                  <th
                    class="col-delimiter text-center"
                    colspan="6"
                  >
                    Déchargement traitement
                  </th>
                  <th
                    class="text-center"
                    colspan="3"
                  >
                    Facturation
                  </th>
                </tr>
                <tr>
                  <th>Date</th>
                  <th>Lieu de livraison</th>
                  <th>Véhicule</th>
                  <th>Chauffeur</th>
                  <th>
                    Cargaison
                    <i
                      v-if="!details"
                      class="fa fa-plus-circle"
                      style="cursor:pointer;"
                      @click="details = true"
                    />
                    <i
                      v-else
                      class="fa fa-minus-circle"
                      style="cursor:pointer;"
                      @click="details = false"
                    />
                  </th>
                  <th class="col-delimiter">
                    Affaire(s)
                  </th>
                  <th colspan="3" />
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="delivery in displayed"
                  class="cte-stock-deliveries__row"
                  :class="{'cte-stock-deliveries__row--ack': saving !== delivery.id && statuses[delivery.id] === 'ack' && bills[delivery.id],
                           'cte-stock-deliveries__row--unvaluable': saving !== delivery.id && statuses[delivery.id] === 'unvaluable'}"
                >
                  <td :class="delivery.id">
                    <input
                      v-model="datetimes[delivery.id]"
                      type="datetime-local"
                      class="cte-stock-deliveries__date"
                    >
                  </td>
                  <td>
                    <input
                      v-if="!delivery.isPlaceAccurate"
                      v-model="places[delivery.id]"
                      type="text"
                      class="cte-stock-deliveries__place"
                    >
                    <span v-else>{{ places[delivery.id] }}</span>
                  </td>
                  <td>
                    <select v-model="vehicles[delivery.id]">
                      <option
                        v-for="vehicle in deliveries.vehicles"
                        :value="vehicle.id === 'XX-XXX-XX' ? vehicle.id : vehicle.label"
                      >
                        {{ vehicle.label }}
                      </option>
                    </select>
                  </td>
                  <td>
                    <select
                      v-if="vehicles[delivery.id] !== 'XX-XXX-XX'"
                      v-model="drivers[delivery.id]"
                    >
                      <option
                        v-for="driver in deliveries.drivers"
                        :value="driver.label"
                      >
                        {{ driver.label }}
                      </option>
                    </select>
                  </td>
                  <td>
                    <ul class="list-unstyled">
                      <li v-for="element in delivery.elements">
                        {{ element.quantity }} x {{ element.label }}
                        <template v-if="details">
                          ({{ element.details.join(', ') }})
                        </template>
                      </li>
                    </ul>
                  </td>
                  <td class="col-delimiter">
                    <ul
                      v-if="delivery.businesses.length > 0"
                      class="list-unstyled"
                    >
                      <li v-for="business in delivery.businesses">
                        {{ business }}
                      </li>
                    </ul>
                    <template v-else>
                      n.a.
                    </template>
                  </td>
                  <td>
                    <select v-model="statuses[delivery.id]">
                      <option value="waiting">
                        En attente
                      </option>
                      <option value="unvaluable">
                        NON valorisable
                      </option>
                      <option value="ack">
                        Valorisable
                      </option>
                    </select>
                  </td>
                  <td>
                    <input
                      v-if="statuses[delivery.id] === 'ack'"
                      v-model="bills[delivery.id]"
                      type="text"
                      placeholder="N° facture si valorisable"
                    >
                  </td>
                  <td>
                    <button
                      class="btn btn-sm btn-primary"
                      @click.prevent="save(delivery.id)"
                    >
                      <i class="fa fa-floppy-o" />
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import CteSelectableStatuses from '../../components/CteSelectableStatuses.vue';
import CteAlert from '../../components/CteAlert.vue';
import CteStockView from './CteStockView.vue';
import UI from '../../ui';

const SAVED_BACKGROUND_PERSISTENCE_SEC = 1;

export default {
    components: {CteStockView, CteSelectableStatuses, CteAlert},
    props: {
        urls: {required: true},
        deliveries: {required: true},
        warehouses: {type: Array, required: true}
    },
    data() {
        const datetimes = {};
        const places = {};
        const drivers = {};
        const vehicles = {};
        const statuses = {};
        const bills = {};
        this.deliveries
            .deliveries
            .forEach(delivery => {
                datetimes[delivery.id] = delivery.dateTimeEditable;
                places[delivery.id] = delivery.place;
                drivers[delivery.id] = delivery.driver;
                vehicles[delivery.id] = delivery.vehicle;
                statuses[delivery.id] = delivery.status;
                bills[delivery.id] = delivery.bill;
            });

        return {
            datetimes,
            places,
            drivers,
            vehicles,
            statuses,
            bills,
            saving: null,
            moved: [],
            details: false
        };
    },
    computed: {
        displayed() {
            return this.deliveries.deliveries.filter(d => !this.moved.includes(d.id));
        }
    },
    methods: {
        save(id) {
            this.saving = id;
            fetch(this.urls.saveDelivery, {
                headers: {'Content-Type': 'application/json'},
                method: 'POST',
                body: JSON.stringify({
                    id,
                    datetime: this.datetimes[id],
                    status: this.statuses[id],
                    bill: this.bills[id],
                    place: this.places[id],
                    vehicle: this.vehicles[id],
                    driver: this.drivers[id]
                })
            })
                .then(response => {
                    if (response.ok) {
                        return response.json();
                    }
                    throw new Error();
                })
                .then(() => {
                    setTimeout(() => this.saving = null, SAVED_BACKGROUND_PERSISTENCE_SEC * 1000);
                })
                .catch(() => UI.error('Erreur lors de la sauvegarde, veuillez réessayer.'))
                .finally(() => this.saving = null);
        }
    }
};
</script>

<style lang="scss">
.cte-stock-deliveries {
    font-size: 12px;

    &__date, &__place {
        font-weight: bold;
    }

    table {
        input, select {
            border: 1px solid #dddddd;
        }
    }

    &__row {
        height: 75px;

        &--unvaluable {
            background-color: #dddddd;

            input, select {
                background-color: #dddddd;
            }
        }

        &--ack {
            background-color: #dff0d8;

            input, select {
                background-color: #dff0d8;
            }
        }
    }
}
</style>
