<template>
  <tr class="activity-edit-slot">
    <td class="activity-col-detail">
      <template v-if="mode === 'fix'">
        {{ label }}
      </template>
      <template v-else-if="slot.isNew || editing">
        <template v-if="mode === 'business' || mode === 'recovery'">
          <div style="display: flex; align-items: center;">
            <span style="margin-right: 5px;">{{ label }}</span>
            <select
              v-model="slot.businessId"
              class="form-control input-sm"
              :required="isBusinessRequired"
            >
              <option
                v-if="!isBusinessRequired"
                value=""
              >
                [Multi affaires]
              </option>
              <option
                v-for="business in businesses"
                :value="business.value"
              >
                {{ business.label }}
              </option>
            </select>
          </div>
        </template>
        <template v-else>
          <input
            v-model="slot.detail"
            type="text"
            class="form-control input-sm"
            :placeholder="label"
          >
        </template>
      </template>
      <template v-else>
        <template v-if="mode === 'business' || mode === 'recovery'">
          {{ label }}
        </template>
        <template v-if="slot.businessId">
          {{ businessLabel(slot.businessId) }}
        </template>
        <template v-else-if="mode !== 'recovery'">
          {{ slot.detail }}
        </template>
      </template>
    </td>
    <td class="activity-col-place">
      <template v-if="slot.isNew || editing">
        <template v-if="mode !== 'fix' && mode !== 'recovery'">
          <input
            v-model="slot.place"
            type="text"
            class="form-control input-sm"
            placeholder="Lieu"
          >
        </template>
      </template>
      <template v-else>
        {{ slot.place }}
      </template>
    </td>
    <td class="activity-col-line-actions col-delimiter">
      <template v-if="mode !== 'fix'">
        <template v-if="slot.isNew">
          <a
            href="#"
            class="btn btn-default btn-xs activity-dyn-add"
            @click.prevent="add()"
          >
            <i class="fa fa-plus" />
          </a>
        </template>
        <template v-else>
          <a
            v-if="!editing"
            href="#"
            class="btn btn-default btn-xs activity-dyn-update"
            @click.prevent="edit()"
          >
            <i class="fa fa-pencil" />
          </a>
          <a
            v-else
            href="#"
            class="btn btn-default btn-xs activity-dyn-update"
            @click.prevent="save()"
          >
            <i class="fa fa-floppy-o" />
          </a>
          <a
            href="#"
            class="btn btn-default btn-xs activity-dyn-delete"
            @click.prevent="remove()"
          >
            <i class="fa fa-trash" />
          </a>
        </template>
      </template>
    </td>
    <td
      v-for="(employee, index) in employees"
      class="activity-col-employee"
      :class="{'last': isLastEmployee(index), 'editing': currentEmployee === employee.id}"
      @mouseenter="$emit('employeeenter', employee.id)"
      @mouseleave="$emit('employeeleave', employee.id)"
      @click="toggleEmployee(employee)"
    >
      <template v-if="!slot.isNew">
        <i
          class="fa"
          :class="{'fa-square-o': !isSelectedEmployee(employee), 'fa-check-square-o': isSelectedEmployee(employee)}"
        />
      </template>
    </td>
    <td
      v-for="(vehicle, index) in vehicles"
      class="activity-col-vehicle"
      :class="{'last': isLastVehicle(index), 'no-vehicles': !withVehicles, 'editing': currentVehicle === vehicle.id}"
      @mouseenter="$emit('vehicleenter', vehicle.id)"
      @mouseleave="$emit('vehicleleave', vehicle.id)"
      @click="toggleVehicle(vehicle)"
    >
      <template v-if="!slot.isNew && withVehicles">
        <i
          class="fa"
          :class="{'fa-square-o': !isSelectedVehicle(vehicle), 'fa-check-square-o': isSelectedVehicle(vehicle)}"
        />
      </template>
    </td>
    <td class="activity-col-contractor">
      <textarea
        v-if="withContractors && !slot.isNew"
        v-model="slot.contractors"
        class="form-control"
      />
    </td>
    <td>&nbsp;</td>
  </tr>
</template>

<script>
export default {
    props: {
        businesses: null,
        employees: null,
        vehicles: null,
        editSlot: null,
        mode: {type: String, required: true},
        label: {type: String, default: null},
        withVehicles: {type: Boolean, default: false},
        withContractors: {type: Boolean, default: false},
        currentEmployee: null,
        currentVehicle: null
    },
    data() {
        return {
            editing: false,
            slot: this.editSlot
        };
    },
    computed: {
        isBusinessRequired() {
            return this.mode === 'business';
        }
    },
    created() {
        if (this.mode === 'business' && !this.slot.businessId) {
            this.slot.businessId = this.businesses[0].value;
        }

        if (this.mode === 'recovery') {
            if (!this.slot.businessId) {
                this.slot.businessId = '';
            }
            this.slot.detail = 'recovery';
        }
    },
    methods: {
        add() {
            this.slot.isNew = false;
            this.$emit('add');
        },
        edit() {
            this.editing = true;
        },
        save() {
            this.editing = false;
        },
        remove() {
            this.$emit('remove');
        },
        businessLabel(businessId) {
            if (!this.businesses) {
                return businessId;
            }

            const business = this.businesses.find(b => parseInt(b.value) === parseInt(businessId));
            if (business) {
                return business.label;
            }

            return businessId;
        },
        isSelectedEmployee(employee) {
            return !!this.slot.employeeIds.find(id => employee.id === id);
        },
        toggleEmployee(employee) {
            const index = this.slot.employeeIds.findIndex(id => employee.id === id);

            if (index !== -1) {
                this.slot.employeeIds.splice(index, 1);
            } else {
                this.slot.employeeIds.push(employee.id);
            }
        },
        isSelectedVehicle(vehicle) {
            return !!this.slot.vehicleIds.find(id => vehicle.id === id);
        },
        toggleVehicle(vehicle) {
            const index = this.slot.vehicleIds.findIndex(id => vehicle.id === id);

            if (index !== -1) {
                this.slot.vehicleIds.splice(index, 1);
            } else {
                this.slot.vehicleIds.push(vehicle.id);
            }
        },
        isLastEmployee(index) {
            return index >= this.employees.length - 1;
        },
        isLastVehicle(index) {
            return index >= this.vehicles.length - 1;
        }
    }
};
</script>

<style>
.activity-edit-slot:hover td {
    color: #8a6d3b !important;
    background-color: #faebcc !important;
}
</style>
