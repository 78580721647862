<template>
  <div class="cte-contractor-form">
    <div
      v-if="contractors.length > 0 && !disabled"
      class="form-group"
    >
      <div class="col-xs-12">
        <select
          v-model="preset"
          class="form-control"
          @change="applyContractorPreset()"
        >
          <option value="">
            -- Etablissement usuel --
          </option>
          <option
            v-for="contractor in contractors"
            :value="contractor.siret"
          >
            {{ contractor.name }} - {{ contractor.address }} - {{ contractor.siret }}
          </option>
        </select>
      </div>
    </div>

    <div
      class="form-group form-group-sm cte-contractor-form__row"
      :class="{'cte-contractor-form__row--preset': !!preset}"
    >
      <label
        :for="id + 'Siret'"
        class="col-sm-3 control-label"
      >
        SIRET <span class="required">*</span>
      </label>
      <div class="col-sm-9">
        <input
          v-model="siret"
          class="form-control"
          minlength="14"
          maxlength="14"
          :name="id + 'Siret'"
          type="text"
          :required="required"
          :disabled="disabled"
          placeholder="Sans espaces ni tirets"
        >
      </div>
    </div>

    <div
      class="form-group form-group-sm cte-contractor-form__row"
      :class="{'cte-contractor-form__row--preset': !!preset}"
    >
      <label
        :for="id + 'Name'"
        class="col-sm-3 control-label"
      >
        Nom <span class="required">*</span>
      </label>
      <div class="col-sm-9">
        <input
          v-model="name"
          class="form-control"
          :name="id + 'Name'"
          type="text"
          :required="required"
          :disabled="disabled"
        >
      </div>
    </div>

    <div
      class="form-group form-group-sm cte-contractor-form__row"
      :class="{'cte-contractor-form__row--preset': !!preset}"
    >
      <label
        :for="id + 'Address'"
        class="col-sm-3 control-label"
      >
        Adresse <span class="required">*</span>
      </label>
      <div class="col-sm-9">
        <input
          v-model="address"
          class="form-control"
          :name="id + 'Address'"
          :disabled="disabled"
          :required="required"
          placeholder="Avec code postal et ville si dispo"
        >
      </div>
    </div>

    <div
      class="form-group form-group-sm cte-contractor-form__row"
      :class="{'cte-contractor-form__row--preset': !!preset}"
    >
      <label
        :for="id + 'Contact'"
        class="col-sm-3 control-label"
      >
        Contact <span class="required">*</span>
      </label>
      <div class="col-sm-9">
        <input
          v-model="contact"
          class="form-control"
          :name="id + 'Contact'"
          type="text"
          :required="required"
          :disabled="disabled"
        >
      </div>
    </div>

    <div
      class="form-group form-group-sm cte-contractor-form__row"
      :class="{'cte-contractor-form__row--preset': !!preset}"
    >
      <label
        :for="id + 'Phone'"
        class="col-sm-3 control-label"
      >
        Téléphone <span class="required">*</span>
      </label>
      <div class="col-sm-9">
        <input
          v-model="phone"
          class="form-control"
          :name="id + 'Phone'"
          type="text"
          :disabled="disabled"
          :required="required"
        >
      </div>
    </div>

    <div
      class="form-group form-group-sm cte-contractor-form__row"
      :class="{'cte-contractor-form__row--preset': !!preset}"
    >
      <label
        :for="id + 'Mail'"
        class="col-sm-3 control-label"
      >
        Email <span class="required">*</span>
      </label>
      <div class="col-sm-9">
        <input
          v-model="mail"
          class="form-control"
          :name="id + 'Mail'"
          type="email"
          :disabled="disabled"
          :required="required"
        >
      </div>
    </div>

    <div
      v-if="withOptionalReceipt"
      class="form-group form-group-sm cte-contractor-form__row"
      :class="{'cte-contractor-form__row--preset': !!preset}"
    >
      <label
        :for="id + 'Exempted'"
        class="col-sm-3 control-label"
      >
        Exempté ?
      </label>
      <div class="col-sm-9">
        <cte-toggle
          v-model="isExempted"
          label-active="Oui"
          label-inactive="Non"
          :name="id + 'Exempted'"
          :disabled="disabled"
        />
      </div>
    </div>

    <div
      v-if="withReceipt || (withOptionalReceipt && !isExempted)"
      class="form-group form-group-sm cte-contractor-form__row"
      :class="{'cte-contractor-form__row--preset': !!preset}"
    >
      <label
        :for="id + 'Receipt'"
        class="col-sm-3 control-label"
      >
        Récépissé <span
          v-if="!isExempted"
          class="required"
        >*</span>
      </label>
      <div class="col-sm-4">
        <input
          v-model="receipt"
          class="form-control"
          :name="id + 'Receipt'"
          type="text"
          :required="required"
          :disabled="disabled"
        >
      </div>
      <div class="col-sm-2">
        <input
          v-model="department"
          class="form-control"
          :name="id + 'Department'"
          minlength="2"
          maxlength="2"
          type="text"
          :required="required"
          :disabled="disabled"
          placeholder="Département"
        >
      </div>
      <div class="col-sm-3">
        <input
          v-model="limit"
          class="form-control"
          :name="id + 'Limit'"
          :min="today"
          :required="required"
          :disabled="disabled"
          type="date"
        >
      </div>
    </div>

    <div
      v-if="withCap"
      class="form-group form-group-sm cte-contractor-form__row"
      :class="{'cte-contractor-form__row--preset': !!preset}"
    >
      <label
        :for="id + 'Cap'"
        class="col-sm-3 control-label"
      >
        CAP <span class="required">*</span>
      </label>
      <div class="col-sm-9">
        <input
          v-model="cap"
          class="form-control"
          :name="id + 'Cap'"
          :disabled="disabled"
          :required="required"
          type="text"
        >
      </div>
    </div>
  </div>
</template>

<script>
import CteToggle from '../components/CteToggle.vue';
import Type from '../services/Type';

export default {
    components: {CteToggle},
    props: {
        id: {type: String, default: null},
        modelValue: {default: null},
        contractors: {type: Array, required: true},
        withReceipt: {type: Boolean, default: false},
        withOptionalReceipt: {type: Boolean, default: false},
        withCap: {type: Boolean, default: false},
        disabled: {type: Boolean, default: false},
        required: {type: Boolean, default: false}
    },
    data() {
        return {
            siret: this.modelValue ? this.modelValue.siret : null,
            name: this.modelValue ? this.modelValue.name : null,
            address: this.modelValue ? this.modelValue.address : null,
            contact: this.modelValue ? this.modelValue.contact : null,
            phone: this.modelValue ? this.modelValue.phone : null,
            mail: this.modelValue ? this.modelValue.mail : null,
            receipt: this.modelValue ? this.modelValue.receipt : null,
            department: this.modelValue ? this.modelValue.department : null,
            limit: this.modelValue ? this.modelValue.limit : null,
            preset: !this.disabled && this.modelValue && this.modelValue.siret ? this.modelValue.siret : '',
            isExempted: this.modelValue ? this.modelValue.isExempted : false,
            cap: this.modelValue ? this.modelValue.cap : null,
        };
    },
    computed: {
        today() {
            return Type.asDateInputValue(new Date());
        }
    },
    watch: {
        siret() {
            this.change();
        },
        name() {
            this.change();
        },
        address() {
            this.change();
        },
        contact() {
            this.change();
        },
        phone() {
            this.change();
        },
        mail() {
            this.change();
        },
        receipt() {
            this.change();
        },
        department() {
            this.change();
        },
        limit() {
            this.change();
        },
        isExempted() {
            this.change();
        },
        cap() {
            this.change();
        }
    },
    methods: {
        applyContractorPreset() {
            if (this.preset) {
                const contractor = this.contractors.find(contractor => contractor.siret === this.preset);

                this.siret = contractor.siret;
                this.name = contractor.name;
                this.address = contractor.address;
                this.contact = contractor.contact;
                this.phone = contractor.phone;
                this.mail = contractor.mail;
                this.isExempted = !contractor.receipt;
                this.limit = contractor.limit;
                this.department = contractor.department;
                this.receipt = contractor.receipt;
                this.cap = contractor.cap;
            }
        },
        change() {
            this.$emit('update:modelValue', {
                siret: this.siret,
                name: this.name,
                address: this.address,
                contact: this.contact,
                phone: this.phone,
                mail: this.mail,
                receipt: this.receipt,
                department: this.department,
                limit: this.limit,
                isExempted: this.isExempted,
                cap: this.cap
            });
        }
    }
};
</script>

<style lang="scss">
.cte-contractor-form {
    &__row {
        &--preset {
            input, select {
                border-color: #337ab7;
            }
        }
    }
}
</style>
