<template>
  <form
    class="form-horizontal"
    method="post"
  >
    <input
      type="hidden"
      name="id"
      :value="existing.id"
    >
    <input
      type="hidden"
      name="date"
      :value="existing.date.date"
    >

    <div class="row">
      <div class="col-md-6">
        <div class="form-group">
          <label class="col-sm-3 control-label">
            Heure début <span class="required">*</span>
          </label>
          <div class="col-sm-3">
            <input
              type="time"
              class="form-control"
              name="started"
              :value="existing.started"
            >
          </div>
        </div>

        <div class="form-group">
          <label class="col-sm-3 control-label">
            Position début
          </label>
          <div class="col-sm-9">
            <cte-map v-model="existing.startPosition" />
          </div>
        </div>
      </div>

      <div class="col-md-6">
        <div class="form-group">
          <label class="col-sm-3 control-label">
            Pause déjeuner <span class="required">*</span>
          </label>
          <div class="col-sm-3">
            <select
              v-model="lunchDuration"
              class="form-control"
              name="lunchDurationMinutes"
            >
              <option
                v-for="duration in lunchDurations"
                :value="duration"
                :selected="lunchDuration === duration"
              >
                {{ interval(duration) }}
              </option>
            </select>
          </div>

          <label class="col-sm-3 control-label">
            Heure fin <span class="required">*</span>
          </label>
          <div class="col-sm-3">
            <input
              type="time"
              class="form-control"
              :value="existing.ended"
              name="ended"
            >
          </div>
        </div>

        <div class="form-group">
          <label class="col-sm-3 control-label">
            Position fin
          </label>
          <div class="col-sm-9">
            <cte-map v-model="existing.endPosition" />
          </div>
        </div>
      </div>
    </div>

    <div class="form-group">
      <div class="col-xs-12 text-right">
        <button
          type="submit"
          class="btn btn-primary btn-default"
        >
          Enregistrer
        </button>
        <a
          :href="urls.cancel"
          class="btn btn-default"
        >Annuler</a>
      </div>
    </div>
  </form>
</template>

<script>
import CteMap from '../components/CteMap.vue';

export default {
    components: {CteMap},
    props: {
        urls: {required: true},
        existing: {type: Object, default: null}
    },
    data() {
        return {
            lunchDuration: this.existing.lunchDurationMinutes
        };
    },
    computed: {
        lunchDurations() {
            return Array.from(Array(9).keys()).map(i => i * 15).filter(t => t >= 30);
        }
    },
    methods: {
        interval(minutes) {
            if (!minutes) {
                return 'Aucune';
            }

            const hours = Math.floor(minutes / 60);
            const remainingMinutes = Math.floor(minutes % 60);

            let readable = hours >= 1 ? hours + 'h' : '';
            readable += remainingMinutes ? remainingMinutes + 'min' : '';

            return readable;
        }
    }
};
</script>
