<template>
  <div>
    <input
      :value="id"
      type="hidden"
      name="id"
    >

    <fieldset>
      <div class="form-group">
        <label
          class="col-sm-3 control-label"
          for="category"
        >
          Catégorie <span class="required">*</span>
        </label>
        <div class="col-sm-7">
          <select
            id="category"
            v-model="category"
            class="form-control"
            required
            name="category"
          >
            <option
              v-for="categoryChoice in categories"
              :key="'categoryChoice-' + categoryChoice.id"
              :value="categoryChoice.id"
            >
              {{ categoryChoice.name }}
            </option>
          </select>
        </div>
        <div class="col-sm-2">
          <cte-toggle
            v-model="icpe"
            exploded
            label-inactive="Hors ICPE"
            label-active="ICPE"
            name="icpe"
          />
        </div>
      </div>

      <div class="form-group">
        <label
          class="col-sm-3 control-label"
          for="packaging"
        >
          Contenant <span
            v-if="isPackagingRequired"
            class="required"
          >*</span>
        </label>
        <div class="col-sm-9">
          <select
            id="packaging"
            v-model="packaging"
            class="form-control"
            name="packaging"
            :required="isPackagingRequired"
          >
            <option v-if="!isPackagingRequired" />
            <option
              v-for="packagingChoice in packagings"
              :key="'packagingChoice-' + packagingChoice.id"
              :value="packagingChoice.id"
            >
              {{ packagingChoice.name }}
            </option>
          </select>
          <p
            v-if="!isPackagingRequired"
            class="help-block"
            style="margin-bottom: 0;"
          >
            Laisser vide si aucun contenant
          </p>
        </div>
      </div>

      <div class="form-group">
        <label
          class="col-sm-3 control-label"
          for="label"
        >
          Désignation du déchet
        </label>
        <div class="col-sm-9">
          <input
            id="label"
            v-model="label"
            type="text"
            name="label"
            placeholder="Ex: disjoncteur, ..."
            class="form-control"
          >
        </div>
      </div>

      <div
        v-if="!id"
        class="form-group"
      >
        <label
          class="col-sm-3 control-label"
          for="quantity"
        >
          Nb. d'éléments <span class="required">*</span>
        </label>
        <div class="col-sm-9">
          <input
            id="quantity"
            v-model="quantity"
            type="number"
            name="quantity"
            min="1"
            step="1"
            class="form-control"
            required
          >
        </div>
      </div>

      <div class="form-group">
        <label
          class="col-sm-3 control-label"
          for="volume"
        >
          Poids ou volume
        </label>
        <div class="col-sm-9">
          <input
            id="volume"
            v-model="volume"
            type="text"
            name="volume"
            :disabled="quantity > 1"
            placeholder="Ex: 2.8T, 600L, 3.5M3"
            class="form-control"
          >
        </div>
      </div>
    </fieldset>

    <fieldset>
      <div class="form-group">
        <label
          class="col-sm-3 control-label"
          for="businessId"
        >
          Affaire <span class="required">*</span>
        </label>
        <div class="col-sm-9">
          <select
            id="businessId"
            v-model="businessId"
            class="form-control"
            required
            name="businessId"
          >
            <option
              v-for="businessChoice in businesses"
              :value="businessChoice.id"
            >
              {{ businessChoice.number }}
            </option>
          </select>
        </div>
      </div>

      <div class="form-group">
        <label
          class="col-sm-3 control-label"
          for="wasteTrackerId"
        >
          BSD
        </label>
        <div class="col-sm-9">
          <select
            id="wasteTrackerId"
            v-model="wasteTrackerId"
            class="form-control"
            :disabled="bsds.length <= 0"
            name="wasteTrackerId"
          >
            <option />
            <option
              v-for="bsdChoice in bsds"
              :value="bsdChoice.id"
            >
              {{ bsdChoice.number }}
            </option>
          </select>
        </div>
      </div>
    </fieldset>
  </div>
</template>

<script>
import CteToggle from '../../components/CteToggle.vue';

export default {
    components: {CteToggle},
    props: {
        categories: {required: true},
        businesses: {required: true},
        packagings: {required: true},
        modelValue: {default: null},
    },
    data() {
        return {
            id: this.modelValue ? this.modelValue.id : null,
            businessId: this.modelValue ? this.modelValue.businessId : null,
            wasteTrackerId: this.modelValue ? this.modelValue.wasteTrackerId : null,
            category: this.modelValue ? this.modelValue.category : null,
            icpe: this.modelValue ? this.modelValue.icpe : false,
            packaging: this.modelValue ? this.modelValue.packaging : null,
            label: this.modelValue ? this.modelValue.label : null,
            quantity: this.modelValue ? this.modelValue.quantity : 1,
            volume: this.modelValue ? this.modelValue.volume : null
        };
    },
    computed: {
        bsds() {
            if (this.businessId) {
                return this.businesses.find(business => business.id === this.businessId).bsds;
            }

            return [];
        },
        isPackagingRequired() {
            return this.category === 'oil';
        }
    }
};
</script>
