import has from 'lodash-es/has';
import get from 'lodash-es/get';
import set from 'lodash-es/set';

class Routing {
    static redirect(url) {
        document.location = url;
    }

    static reload() {
        document.location.reload();
    }

    static refreshUsingQueryStringParameter(params, url) {
        let updateQueryStringParameter = function (url, params) {
            let removeQuerystring = function (url) {
                return url.split('?')[0];
            };

            let queryStringToObject = () => {
                let decode = (queryString) => {
                    const queryStringPieces = queryString.split('&');
                    const decodedQueryString = {};

                    for (const piece of queryStringPieces) {
                        let [key, value] = piece.split('=');
                        value = value || '';
                        if (has(decodedQueryString, key)) {
                            const currentValueForKey = get(decodedQueryString, key);
                            if (!Array.isArray(currentValueForKey)) {
                                set(decodedQueryString, key, [currentValueForKey, value]);
                            } else {
                                currentValueForKey.push(value);
                            }
                        } else {
                            set(decodedQueryString, key, value);
                        }
                    }
                    return decodedQueryString;
                };

                let splits = url.split('?');

                if (splits.length <= 1) {
                    return {};
                }

                return decode(decodeURIComponent(splits[1]));
            };

            let objectToQueryString = function (obj, prefix) {
                let str = [];
                let p;
                for (p in obj) {
                    if (has(obj, p)) {
                        let k = prefix ? prefix + '[' + p + ']' : p;
                        let v = obj[p];
                        str.push((v !== null && typeof v === 'object') ?
                            objectToQueryString(v, k) :
                            encodeURIComponent(k) + '=' + encodeURIComponent(v));
                    }
                }

                return str.join('&');
            };

            return removeQuerystring(url) + '?' + objectToQueryString({...queryStringToObject(url), ...params});
        };

        this.redirect(updateQueryStringParameter(url ? url : window.location.href, params));
    }
}

export default Routing;
