<template>
  <fieldset class="cargo">
    <legend class="cargo__title">
      <div>
        {{ title }}
        <div class="cargo__title__hint">
          <template v-if="!id || elements.length <= 1">
            Ceci ne s'appliquera qu'à cet IUS. Pour traiter plusieurs IUS en même temps,
            il est souvent possible de passer par une entrée ou sortie de stock.
          </template>
          <template v-else>
            Attention, ceci s'appliquera à tous les IUS suivant : {{ elements.join(', ') }}.
          </template>
        </div>
      </div>
      <div v-if="deletable && id">
        <a
          :href="'/stock/cargo/delete' + returnUrlParams + '&cargoId=' + id"
          class="btn btn-default"
          data-toggle="confirmation"
          :data-btn-ok-label="'Supprimer ' + ((id && elements.length > 1) ? 'pour tous' : '')"
        >
          <i class="fa fa-trash" />
        </a>
      </div>
    </legend>

    <input
      type="hidden"
      :name="name + '[id]'"
      :value="id"
    >

    <div class="form-group">
      <label
        class="col-sm-3 control-label"
        for="datetime"
      >
        Date et heure
      </label>
      <div class="col-sm-9">
        <input
          id="datetime"
          v-model="datetime"
          type="datetime-local"
          :name="name + '[datetime]'"
          class="form-control"
        >
      </div>
    </div>

    <div class="form-group">
      <label
        class="col-sm-3 control-label"
        for="vehicleId"
      >
        Véhicule
      </label>
      <div class="col-sm-9">
        <select
          id="vehicleId"
          v-model="vehicleId"
          class="form-control"
          :name="name + '[vehicleId]'"
          :required="!!datetime"
        >
          <option
            v-for="vehicleChoice in allVehicles"
            :value="vehicleChoice.id"
            :selected="vehicleChoice.id === vehicleId"
          >
            {{ vehicleChoice.name }} ({{ vehicleChoice.number }})
          </option>
        </select>
      </div>
    </div>

    <div class="form-group">
      <label
        class="col-sm-3 control-label"
        for="driverId"
      >
        Chauffeur
      </label>
      <div class="col-sm-9">
        <select
          id="driverId"
          v-model="driverId"
          class="form-control"
          :name="name + '[driverId]'"
          :disabled="vehicleId === -1"
          :required="!!datetime"
        >
          <option
            v-for="driverChoice in drivers"
            :value="driverChoice.id"
            :selected="driverChoice.id === driverId"
          >
            {{ driverChoice.firstname }} {{ driverChoice.lastname }}
          </option>
        </select>
      </div>
    </div>

    <input
      v-if="warehouseId"
      type="hidden"
      :name="name + '[warehouseId]'"
      :value="warehouseId"
    >
    <div
      v-else
      class="form-group"
    >
      <label
        class="col-sm-3 control-label"
        for="place"
      >
        Lieu
      </label>
      <div class="col-sm-9">
        <input
          id="place"
          v-model="place"
          type="text"
          :name="name + '[place]'"
          class="form-control"
          :required="!!datetime"
        >
      </div>
    </div>
  </fieldset>
</template>

<script>
export default {
    props: {
        title: {type: String, required: true},
        name: {type: String, required: true},
        vehicles: {type: Array, required: true},
        drivers: {type: Array, required: true},
        warehouseId: {type: Number, default: null},
        modelValue: {default: null},
        deletable: {type: Boolean, default: false}
    },
    data() {
        return {
            id: this.modelValue ? this.modelValue.id : null,
            datetime: this.modelValue ? this.modelValue.datetime : null,
            place: this.modelValue ? this.modelValue.place : null,
            vehicleId: this.modelValue ? this.modelValue.vehicleId : null,
            driverId: this.modelValue ? this.modelValue.driverId : null,
            elements: this.modelValue && this.modelValue.elements ? this.modelValue.elements : []
        };
    },
    computed: {
        allVehicles() {
            return [...this.vehicles, {
                id: -1,
                name: 'Prestataire',
                number: 'XX-XXX-XX'
            }];
        },
        returnUrlParams() {
            return window.location.search;
        }
    },
    watch: {
        datetime() {
            this.change();
        },
        place() {
            this.change();
        },
        vehicleId() {
            this.change();
        },
        driverId() {
            this.change();
        },
    },
    methods: {
        change() {
            this.$emit('update:modelValue', {
                datetime: this.datetime,
                place: this.place,
                vehicleId: this.vehicleId,
                driverId: this.driverId
            });
        }
    }
};
</script>

<style lang="scss">
.cargo {
  &__title {
    display: flex;
    align-items: center;
    justify-content: space-between;

    &__hint {
      margin-top: .5rem;
      font-size: 0.9rem;
      color: #666;
    }
  }
}
</style>
