<template>
  <select
    v-model="view"
    class="cte-stock-view input-sm"
    :disabled="disabled"
    @change="onChange($event)"
  >
    <option value="stock">
      Vue stock
    </option>
    <option value="deliveries">
      Vue livraisons
    </option>
    <option
      v-for="flow in flows"
      :value="'elements_' + flow.warehouseId"
    >
      Vue flux {{ flow.label }}
    </option>
  </select>
</template>

<script>
import Routing from '../../services/Routing';

export default {
    props: {
        warehouses: {type: Array, required: true},
        refreshUrl: {type: String, required: true},
        current: {type: String, required: true},
        disabled: {type: Boolean, default: false}
    },
    data() {
        return {
            view: this.current
        };
    },
    computed: {
        flows() {
            return [
                ...this.warehouses.map(warehouse => ({label: warehouse.label, warehouseId: warehouse.id})),
                {label: 'déchets', warehouseId: 0}
            ];
        }
    },
    methods: {
        onChange() {
            Routing.redirect(this.refreshUrl + '?stock_view=' + this.view);
        }
    }
};
</script>

<style lang="scss">
.cte-stock-view {
    margin-right: .5rem;
}
</style>
