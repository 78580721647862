<template>
  <div class="cte-workflow">
    <template v-for="(state, index) in states">
      <div
        v-if="index > 0"
        class="cte-workflow__separator"
        :class="{'cte-workflow__separator--done': state.isDone, 'cte-workflow__separator--late': state.isLate, 'cte-workflow__separator--verylate': state.isVeryLate}"
      >
        <i class="fa fa-long-arrow-right" />
      </div>
      <div
        class="cte-workflow__state"
        :class="{'cte-workflow__state--done': state.isDone, 'cte-workflow__state--late': state.isLate, 'cte-workflow__state--verylate': state.isVeryLate}"
        data-toggle="tooltip"
        data-placement="top"
        :title="state.label"
      />
    </template>
  </div>
</template>

<script>
export default {
    props: {
        states: {type: Array, required: true}
    }
};
</script>

<style lang="scss">
$done: #5cb85c;
$late: #f0ad4e;
$verylate: red;
$inactive: lightgray;

.cte-workflow {
    display: flex;
    align-items: center;
    font-size: .7rem;

    &__separator {
        color: $inactive;

        &--late {
            color: $late;
        }

        &--verylate {
            color: $verylate;
        }

        &--done {
            color: $done;
        }
    }

    &__state {
        height: 16px;
        width: 16px;
        border-radius: 50%;
        background: $inactive;
        color: white;

        &--late {
            background: $late;
        }

        &--verylate {
            background: $verylate;
        }

        &--done {
            background: $done;
        }
    }
}
</style>
