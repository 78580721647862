class Type {
    static asDateInputValue(date) {
        date.setMinutes(date.getMinutes() - date.getTimezoneOffset());
        return date.toJSON().slice(0, 10);
    }

    static asTimeInputValue(date) {
        date.setMinutes(date.getMinutes() - date.getTimezoneOffset());
        return date.toJSON().slice(11, 16);
    }

    static asDateTimeInputValue(date) {
        date.setMinutes(date.getMinutes() - date.getTimezoneOffset());
        return date.toJSON().slice(0, 16);
    }
}

export default Type;
