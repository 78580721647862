<template>
  <div class="row">
    <div class="col-xs-12">
      <form
        class="form-horizontal"
        method="post"
        enctype="multipart/form-data"
      >
        <cte-alert
          v-if="form.errorMsg"
          type="danger"
          :msg="form.errorMsg"
        />

        <input
          type="hidden"
          name="csrfToken"
          :value="form.csrfToken"
        >
        <input
          type="hidden"
          name="id"
          :value="form.values.id"
        >

        <fieldset>
          <div class="form-group">
            <label
              for="report-businessId"
              class="col-sm-3 control-label"
            >Affaire <span class="required">*</span></label>
            <div class="col-sm-9">
              <select
                v-if="!business || !business.id"
                id="report-businessId"
                name="businessId"
                class="form-control"
                required
              >
                <option
                  v-for="choice in form.choices.businessId"
                  :value="choice.value"
                >
                  {{ choice.label }}
                </option>
              </select>
              <template v-else>
                <input
                  type="hidden"
                  name="businessId"
                  :value="business.id"
                >
                {{ business.estimateNumber }}
              </template>
            </div>
          </div>

          <div class="form-group">
            <label
              for="report-type"
              class="col-sm-3 control-label"
            >Type <span class="required">*</span></label>
            <div class="col-sm-9">
              <select
                id="report-type"
                v-model="type"
                name="type"
                class="form-control"
                required
              >
                <option
                  v-for="choice in form.choices.type"
                  :value="choice.value"
                >
                  {{ choice.label }}
                </option>
              </select>
            </div>
          </div>

          <div class="form-group">
            <label
              for="report-increment"
              class="col-sm-3 control-label"
            >Incrément <span class="required">*</span></label>
            <div class="col-sm-9">
              <input
                id="report-increment"
                type="number"
                name="increment"
                class="form-control"
                :value="form.values.increment"
                min="1"
                max="99"
                step="1"
                required
                autofocus
              >
            </div>
          </div>

          <div class="form-group">
            <label
              for="report-date"
              class="col-sm-3 control-label"
            >Date <span class="required">*</span></label>
            <div class="col-sm-9">
              <input
                id="report-date"
                type="date"
                name="date"
                class="form-control"
                :value="form.values.date"
                required
              >
            </div>
          </div>

          <div
            v-if="type === 'rh'"
            class="form-group"
          >
            <label
              for="report-forWeek"
              class="col-sm-3 control-label"
            >Période couverte <span class="required">*</span></label>
            <div class="col-sm-3">
              <select
                id="report-forWeek"
                name="forWeek"
                class="form-control"
                required
              >
                <option
                  v-for="choice in form.choices.forWeek"
                  :value="choice.value"
                  :selected="choice.isSelected"
                >
                  {{ choice.label }}
                </option>
              </select>
            </div>
            <div class="col-sm-6">
              <select
                id="report-forYear"
                name="forYear"
                class="form-control"
                required
              >
                <option
                  v-for="choice in form.choices.forYear"
                  :value="choice.value"
                  :selected="choice.isSelected"
                >
                  {{ choice.label }}
                </option>
              </select>
            </div>
          </div>

          <div class="form-group">
            <label
              for="report-clientSendingDate"
              class="col-sm-3 control-label"
            >Date d'envoi au client (si pertinent)</label>
            <div class="col-sm-9">
              <input
                id="report-clientSendingDate"
                type="date"
                name="clientSendingDate"
                class="form-control"
                :value="form.values.clientSendingDate"
              >
            </div>
          </div>
        </fieldset>

        <fieldset class="cte-fieldset--separate">
          <div class="form-group">
            <label
              for="report-isEncrypted"
              class="col-sm-3 control-label"
            >
              Fichiers chiffrés ?
            </label>
            <div
              :class="'col-sm-9 encrypted form-group-toggle ' + (form.values.id ? 'form-group-toggle--disabled' : '')"
              :title="form.values.id ? 'Ne peut être changé si déjà des documents' : ''"
            >
              <cte-toggle
                v-model="isEncrypted"
                label-inactive="centrales nucléaires ? Tous les documents doivent être chiffrés !"
                label-active="centrales nucléaires ? Tous les documents doivent être chiffrés !"
                name="isEncrypted"
              />
            </div>
          </div>

          <div class="form-group">
            <label
              for="report-word"
              class="col-sm-3 control-label"
            >Fichier word <span class="required">*</span></label>
            <div class="col-sm-9">
              <div class="cte-input-file">
                <div class="cte-input-file__browse">
                  <label class="btn btn-default">
                    <i class="fa fa-hdd-o" /> <input
                      id="report-word"
                      type="file"
                      name="word"
                      accept=".docx"
                    >
                  </label>
                  <div
                    class="btn btn-default cte-input-file__browse__clear"
                    style="display: none;"
                  >
                    <i class="fa fa-trash-o" />
                  </div>
                </div>
                <div class="cte-input-file__path">
                  <input
                    type="text"
                    class="form-control"
                    :value="form.values.existingWord"
                    required
                    onkeydown="return false"
                  >
                  <template v-if="form.values.existingWord">
                    <span class="hidden-xs cte-input-file__path__current">Fichier actuel :</span>
                    <cte-report-download
                      :id="form.values.id"
                      class="btn-link"
                      :is-encrypted="isEncrypted"
                      :name="form.values.existingWord"
                      format="word"
                    />
                  </template>
                </div>
              </div>
            </div>
          </div>

          <div class="form-group">
            <label
              for="report-pdf"
              class="col-sm-3 control-label"
            >Fichier PDF <span class="required">*</span></label>
            <div class="col-sm-9">
              <div class="cte-input-file">
                <div class="cte-input-file__browse">
                  <label class="btn btn-default">
                    <i class="fa fa-hdd-o" /> <input
                      id="report-pdf"
                      type="file"
                      name="pdf"
                      accept="application/pdf"
                    >
                  </label>
                  <div
                    class="btn btn-default cte-input-file__browse__clear"
                    style="display: none;"
                  >
                    <i class="fa fa-trash-o" />
                  </div>
                </div>
                <div class="cte-input-file__path">
                  <input
                    type="text"
                    class="form-control"
                    :value="form.values.existingPdf"
                    required
                    onkeydown="return false"
                  >
                  <template v-if="form.values.existingPdf">
                    <span class="hidden-xs cte-input-file__path__current">Fichier actuel :</span>
                    <cte-report-download
                      :id="form.values.id"
                      class="btn-link"
                      :is-encrypted="isEncrypted"
                      :name="form.values.existingPdf"
                      format="pdf"
                    />
                  </template>
                </div>
              </div>
            </div>
          </div>

          <!-- autocomplete hack -->
          <input
            type="text"
            style="opacity: 0;width: 0;height: 0;margin-left: -5px;"
          >

          <div
            v-if="isEncrypted"
            class="encrypted-on"
          >
            <div class="form-group">
              <label
                for="report-filePassword"
                class="col-sm-3 control-label"
              >
                Mot de passe de chiffrement
                <span class="required">*</span>
              </label>
              <div class="col-sm-9">
                <input
                  id="report-filePassword"
                  type="password"
                  name="filePassword"
                  class="form-control"
                  autocomplete="new-pasword"
                  minlength="6"
                  required
                >
              </div>
            </div>
          </div>
        </fieldset>

        <div class="form-group">
          <div class="col-sm-offset-3 col-sm-9">
            <button
              type="submit"
              class="btn btn-primary btn-default"
            >
              Enregistrer
            </button>

            <a
              v-if="from === 'report'"
              :href="urls.reportIndex"
              class="btn btn-default"
            >Annuler</a>
            <a
              v-else
              :href="urls.businessEdit"
              class="btn btn-default"
            >Annuler</a>
          </div>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import CteReportDownload from './CteReportDownload.vue';
import CteAlert from '../components/CteAlert.vue';
import CteToggle from '../components/CteToggle.vue';

export default {
    components: {CteToggle, CteAlert, CteReportDownload},
    props: ['form', 'from', 'urls', 'business'],
    data() {
        return {
            type: this.form.values.type ? this.form.values.type : this.form.choices.type[0].value,
            isEncrypted: this.form.values.isEncrypted ? true : false
        };
    }
};
</script>
