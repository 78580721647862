<template>
  <div>
    <div class="modal-backdrop fade in" />
    <div
      id="clocking-balance"
      class="modal fade in"
      style="display: block;"
    >
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">
              {{ new Date(balance.date).toLocaleDateString() }}
            </h5>
          </div>
          <div class="modal-body">
            <form
              ref="form"
              method="post"
              class="form-horizontal balance-form"
            >
              <input
                v-if="balance.id"
                type="hidden"
                name="id"
                :value="balance.id"
              >
              <input
                type="hidden"
                name="date"
                :value="balance.date"
              >
              <input
                type="hidden"
                name="employeeId"
                :value="balance.employeeId"
              >

              <div
                v-if="!balance.type"
                class="form-group"
              >
                <label class="col-sm-3 control-label">
                  Type <span class="required">*</span>
                </label>
                <div class="col-sm-9">
                  <select
                    v-model="type"
                    class="form-control"
                    name="type"
                  >
                    <option
                      v-for="availableType in availableTypes"
                      :value="availableType.code"
                    >
                      {{ availableType.label }}
                    </option>
                  </select>
                </div>
              </div>
              <input
                v-else
                type="hidden"
                name="type"
                :value="balance.type"
              >

              <input
                type="hidden"
                name="extraMinutes"
                :value="extraMinutes"
              >
              <div class="form-group">
                <label class="col-sm-3 control-label">
                  Heures supp. <span class="required">*</span>
                </label>
                <div class="col-sm-9">
                  <div
                    class="balance-form__extra"
                    style="display: flex;"
                  >
                    <cte-toggle
                      v-model="isNegative"
                      class="balance-form__extra__part"
                      name="isNegative"
                      label-inactive="Ajout h. supp."
                      label-active="Diminution h. supp."
                      :disabled="isExtraDisabled || isNegativeDisabled"
                    />
                    <input
                      v-model="hours"
                      min="0"
                      class="form-control balance-form__extra__part"
                      type="number"
                      step="1"
                      :disabled="isExtraDisabled"
                    >
                    <span class="balance-form__extra__part">h</span>
                    <input
                      v-model="minutes"
                      min="0"
                      max="59"
                      class="form-control balance-form__extra__part"
                      type="number"
                      step="1"
                      :disabled="isExtraDisabled"
                    >
                    <span class="balance-form__extra__part">min</span>
                  </div>
                </div>
              </div>
            </form>
          </div>
          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-default"
              @click.prevent="$emit('close')"
            >
              Annuler
            </button>
            <button
              type="button"
              class="btn btn-primary"
              @click="$refs.form.submit()"
            >
              Enregistrer
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CteToggle from '../components/CteToggle.vue';

const RECOVERY_DEFAULT_HOURS = 7;

export default {
    components: {CteToggle},
    props: {
        balance: {type: Object, required: true},
        ongoingExtraMinutes: {type: Number}
    },
    data() {
        return {
            type: this.balance.type ? this.balance.type : 'work',
            hours: null,
            minutes: null,
            isNegative: null
        };
    },
    computed: {
        availableTypes() {
            return [
                {code: 'recovery', label: 'Récupération'},
                {code: 'salary', label: 'Paiement'},
                {code: 'work', label: 'Pointage'}
            ];
        },
        extraMinutes() {
            return (parseInt(this.hours ? this.hours : 0) * 60 + parseInt(this.minutes ? this.minutes : 0))
                * (this.isNegative ? -1 : 1);
        },
        isExtraDisabled() {
            return false;
            // return this.type === 'recovery';
        },
        isNegativeDisabled() {
            return this.type === 'recovery' || this.type === 'salary';
        }
    },
    watch: {
        type(newVal) {
            if (newVal === 'recovery') {
                this.decompose((60 * RECOVERY_DEFAULT_HOURS) * -1);
            } else if (newVal === 'salary') {
                const payment = Math.min(this.ongoingExtraMinutes * -1, -.2);
                this.decompose(payment);
            } else {
                this.decompose(0);
            }
        }
    },
    created() {
        this.decompose(this.balance.extra);
    },
    methods: {
        decompose(minutes) {
            this.hours = Math.floor(Math.abs(minutes) / 60);
            this.minutes = Math.floor(Math.abs(minutes) % 60);
            this.isNegative = minutes < 0;
        }
    }
};
</script>

<style lang="scss">
.balance-form {
    &__extra {
        display: flex;
        align-items: center;

        &__part {
            margin: 0 5px;
        }

        input {
            width: 75px;
        }
    }
}

#clocking-balance .modal-header {
    padding: .5rem;

    .modal-title {
        font-weight: bold;
        text-align: center;
        font-size: 1.1rem;
    }
}
</style>
