<template>
  <select
    class="form-control input-sm"
    required
    name="businessId"
    style="width: 100%;"
  >
    <option value="">
      Affaire *
    </option>
    <option
      v-for="choice in businesses"
      :value="choice.value"
      :selected="parseInt(selected) === parseInt(choice.value)"
    >
      {{ choice.label }}
    </option>
  </select>
</template>

<script>
export default {
    props: {
        businessesProp: {type: Array, default: null},
        selected: {type: String, default: null}
    },
    data() {
        return {
            businesses: []
        };
    },
    created() {
        this.businesses = this.businessesProp ? this.businessesProp : window.data;
    }
};
</script>
