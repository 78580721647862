<template>
  <div class="row">
    <div class="col-xs-12">
      <div id="activity-edit-tables">
        <table class="table table-condensed table-rotated">
          <thead>
            <tr>
              <th class="activity-edit-date">
                {{ date }}
              </th>

              <th
                v-for="employee in employees"
                class="rotate activity-col-employee"
              >
                <div
                  :class="{'text-warning': nbActivities(employee) === 2, 'text-danger': nbActivities(employee) > 2}"
                >
                  <span
                    :class="{'activity-type-nothing': nothingFor(employee), 'editing': currentEmployee === employee.id}"
                  >
                    <s v-if="employee.isDeleted">{{ employee.label }}</s>
                    <template v-else>{{ employee.label }}</template>
                  </span>
                </div>
              </th>

              <th
                v-for="vehicle in vehicles"
                class="rotate activity-col-vehicle"
              >
                <div>
                  <span :class="{'editing': currentVehicle === vehicle.id}">
                    <s v-if="vehicle.isDeleted">{{ vehicle.label }}</s>
                    <template v-else>{{ vehicle.label }}</template>
                  </span>
                </div>
              </th>

              <th class="activity-col-contractor">
                Prestataires
              </th>

              <th>&nbsp;</th>
            </tr>
          </thead>
        </table>

        <div class="activity-edit-type activity-type-work">
          <div>Travaux</div>
        </div>

        <div class="activities-type-work-toggled">
          <table class="table table-condensed activity-edit-types activities-type-work">
            <tbody>
              <cte-activity-edit-slot
                v-for="(slot, index) in workSlots"
                :key="slot.id"
                class="activity-row-type-work"
                :edit-slot="slot"
                :businesses="businesses"
                :vehicles="vehicles"
                :employees="employees"
                :current-employee="currentEmployee"
                :current-vehicle="currentVehicle"
                mode="business"
                label=""
                with-vehicles
                with-contractors
                @add="addSlot(workSlots)"
                @remove="removeSlot(workSlots, index)"
                @employeeenter="employeeEnter($event)"
                @employeeleave="employeeLeave($event)"
                @vehicleenter="vehicleEnter($event)"
                @vehicleleave="vehicleLeave($event)"
              />
            </tbody>
          </table>
        </div>

        <div class="activity-edit-type activity-type-commercial">
          <div>Commercial</div>
        </div>

        <div class="activities-type-commercial-toggled">
          <table class="table table-condensed activity-edit-types activities-type-commercial">
            <tbody>
              <cte-activity-edit-slot
                v-for="(slot, index) in commercialSlots"
                :key="slot.id"
                class="activity-row-type-commercial"
                :edit-slot="slot"
                :vehicles="vehicles"
                :employees="employees"
                :current-employee="currentEmployee"
                :current-vehicle="currentVehicle"
                mode="free"
                label="Nom client"
                with-vehicles
                @add="addSlot(commercialSlots)"
                @remove="removeSlot(commercialSlots, index)"
                @employeeenter="employeeEnter($event)"
                @employeeleave="employeeLeave($event)"
                @vehicleenter="vehicleEnter($event)"
                @vehicleleave="vehicleLeave($event)"
              />
            </tbody>
          </table>
        </div>

        <div class="activity-edit-type activity-type-nonbill">
          <div>Non facturable</div>
        </div>

        <div class="activities-type-nonbill-toggled">
          <table class="table table-condensed activity-edit-types activities-type-nonbill">
            <tbody>
              <!-- Libre --->
              <cte-activity-edit-slot
                v-for="(slot, index) in nonBillFreeSlots"
                :key="slot.id"
                class="activity-row-type-nonbill"
                :edit-slot="slot"
                :vehicles="vehicles"
                :employees="employees"
                :current-employee="currentEmployee"
                :current-vehicle="currentVehicle"
                mode="free"
                label="Ex: Bureau, Rangement, ..."
                with-vehicles
                @add="addSlot(nonBillFreeSlots)"
                @remove="removeSlot(nonBillFreeSlots, index)"
                @employeeenter="employeeEnter($event)"
                @employeeleave="employeeLeave($event)"
                @vehicleenter="vehicleEnter($event)"
                @vehicleleave="vehicleLeave($event)"
              />
              <!-- Projets internes --->
              <cte-activity-edit-slot
                v-for="(slot, index) in nonBillWorkSlots"
                :key="slot.id"
                class="activity-row-type-nonbill"
                :edit-slot="slot"
                mode="business"
                label="Projet"
                :vehicles="vehicles"
                :employees="employees"
                :current-employee="currentEmployee"
                :current-vehicle="currentVehicle"
                :businesses="nonBillableBusinesses"
                with-vehicles
                @add="addSlot(nonBillWorkSlots)"
                @remove="removeSlot(nonBillWorkSlots, index)"
                @employeeenter="employeeEnter($event)"
                @employeeleave="employeeLeave($event)"
                @vehicleenter="vehicleEnter($event)"
                @vehicleleave="vehicleLeave($event)"
              />
            </tbody>
          </table>
        </div>

        <div
          class="activity-edit-type activity-type-off"
          style="cursor:pointer;"
          @click.stop="toggleOffClosed()"
        >
          <div>Formation / Congés / Maladie</div>

          <div class="activity-edit-type-toggle">
            <i
              class="fa"
              :class="{'fa-angle-down': !isOffClosed, 'fa-angle-up': isOffClosed}"
            />
          </div>
        </div>

        <div class="activities-type-off-sum">
          <div v-for="employee in employees">
            <i
              class="fa"
              :class="{'fa-square-o': !isEmployeeOff(employee), 'fa-check-square-o': isEmployeeOff(employee)}"
              style="font-size: 17px;"
            />
          </div>
        </div>

        <div
          v-if="!isOffClosed"
          class="activities-type-off-toggled"
        >
          <table class="table table-condensed activity-edit-types activities-type-off">
            <tbody>
              <cte-activity-edit-slot
                class="activity-row-type-off"
                :edit-slot="holidaySlot"
                :vehicles="vehicles"
                :employees="employees"
                :current-employee="currentEmployee"
                :current-vehicle="currentVehicle"
                mode="fix"
                label="Congés"
                @employeeenter="employeeEnter($event)"
                @employeeleave="employeeLeave($event)"
                @vehicleenter="vehicleEnter($event)"
                @vehicleleave="vehicleLeave($event)"
              />
              <cte-activity-edit-slot
                class="activity-row-type-off"
                :edit-slot="sickSlot"
                :vehicles="vehicles"
                :employees="employees"
                :current-employee="currentEmployee"
                mode="fix"
                label="Maladie"
                @employeeenter="employeeEnter($event)"
                @employeeleave="employeeLeave($event)"
                @vehicleenter="vehicleEnter($event)"
                @vehicleleave="vehicleLeave($event)"
              />
              <!-- Récupération --->
              <cte-activity-edit-slot
                v-for="(slot, index) in offRecoverySlots"
                :key="slot.id"
                class="activity-row-type-off"
                :edit-slot="slot"
                mode="recovery"
                label="Récup."
                :vehicles="vehicles"
                :employees="employees"
                :current-employee="currentEmployee"
                :current-vehicle="currentVehicle"
                :businesses="businesses"
                @add="addSlot(offRecoverySlots)"
                @remove="removeSlot(offRecoverySlots, index)"
                @employeeenter="employeeEnter($event)"
                @employeeleave="employeeLeave($event)"
                @vehicleenter="vehicleEnter($event)"
                @vehicleleave="vehicleLeave($event)"
              />
              <!-- Formation --->
              <cte-activity-edit-slot
                v-for="(slot, index) in offTrainingSlots"
                :key="slot.id"
                class="activity-row-type-off"
                :edit-slot="slot"
                :vehicles="vehicles"
                :employees="employees"
                :current-employee="currentEmployee"
                :current-vehicle="currentVehicle"
                mode="free"
                label="Nom formation"
                with-vehicles
                @add="addSlot(offTrainingSlots)"
                @remove="removeSlot(offTrainingSlots, index)"
                @employeeenter="employeeEnter($event)"
                @employeeleave="employeeLeave($event)"
                @vehicleenter="vehicleEnter($event)"
                @vehicleleave="vehicleLeave($event)"
              />
            </tbody>
          </table>
        </div>
      </div>

      <div class="activity-edit-save">
        <a
          class="btn btn-primary btn-block activity-save"
          href="#"
          @click.prevent="save()"
        >Enregistrer</a>
      </div>
    </div>
  </div>
</template>

<script>
import CteActivityEditSlot from './CteActivityEditSlot.vue';
import Misc from '../../services/Misc';

const makeNewSlot = () => {
    return {
        id: Misc.randomId('_'),
        isNew: true,
        businessId: null,
        detail: null,
        place: null,
        employeeIds: [],
        vehicleIds: [],
        contractors: null
    };
};

export default {
    components: {CteActivityEditSlot},
    props: {
        date: null,
        dateData: null,
        back: null,
        employees: null,
        vehicles: null,
        businesses: null,
        nonBillableBusinesses: null,
        current: null
    },
    data() {
        return {
            workSlots: [],
            commercialSlots: [],
            nonBillFreeSlots: [],
            nonBillWorkSlots: [],
            holidaySlot: null,
            sickSlot: null,
            offRecoverySlots: [],
            offTrainingSlots: [],
            isOffClosed: true,
            currentEmployee: null,
            currentVehicle: null
        };
    },
    computed: {
        saved() {
            return [
                ...this.workSlots.filter(slot => !slot.isNew).map(slot => this.slotToData('work', slot)),
                ...this.commercialSlots.filter(slot => !slot.isNew).map(slot => this.slotToData('commercial', slot)),
                ...this.nonBillFreeSlots.filter(slot => !slot.isNew).map(slot => this.slotToData('nonbill', slot)),
                ...this.nonBillWorkSlots.filter(slot => !slot.isNew).map(slot => this.slotToData('nonbill', slot)),
                this.slotToData('off', this.holidaySlot),
                this.slotToData('off', this.sickSlot),
                ...this.offRecoverySlots.filter(slot => !slot.isNew).map(slot => this.slotToData('off', slot)),
                ...this.offTrainingSlots.filter(slot => !slot.isNew).map(slot => this.slotToData('off', slot)),
            ];
        }
    },
    created() {
        this.initSlots();
    },
    methods: {
        addSlot(slots) {
            slots.push(makeNewSlot());
        },
        removeSlot(slots, index) {
            slots.splice(index, 1);
        },
        toggleOffClosed() {
            this.isOffClosed = !this.isOffClosed;
        },
        existings(code) {
            return this.current.find(group => group.code === code).existingActivities;
        },
        existingOff(detail) {
            return this.existings('off').find(existing => existing.activity.detail === detail);
        },
        initSlots() {
            this.workSlots = this.initSlot(this.existings('work'));

            this.commercialSlots = this.initSlot(this.existings('commercial'));

            this.nonBillFreeSlots = this.initSlot(this.existings('nonbill').filter(existing => !existing.activity.businessId));
            this.nonBillWorkSlots = this.initSlot(this.existings('nonbill').filter(existing => !!existing.activity.businessId));

            const holiday = this.existingOff('holiday');
            this.holidaySlot = holiday ? this.makeExistingSlot(holiday) : makeNewSlot();

            const sick = this.existingOff('sick');
            this.sickSlot = sick ? this.makeExistingSlot(sick) : makeNewSlot();

            this.offRecoverySlots = this.initSlot(
                this.existings('off')
                    .filter(existing => 'recovery' === existing.activity.detail
                        && (Object.values(existing.employees).length > 0 || existing.activity.businessId))
            );

            this.offTrainingSlots = this.initSlot(this.existings('off').filter(existing => !['holiday', 'sick', 'recovery'].includes(existing.activity.detail)));
        },
        initSlot(existings) {
            let slots = existings.map(existing => this.makeExistingSlot(existing));
            slots.push(makeNewSlot());

            return slots;
        },
        makeExistingSlot(existing) {
            const activity = existing.activity;
            return {
                id: activity.id,
                isNew: false,
                businessId: activity.businessId,
                detail: activity.detail,
                place: activity.place,
                employeeIds: Object.values(existing.employees).map(employee => employee.id),
                vehicleIds: Object.values(existing.vehicles).map(vehicle => vehicle.id),
                contractors: existing.contractors
            };
        },
        save() {
            $.post({
                url: this.back,
                data: {activities: this.saved}
            });
        },
        nbActivities(employee) {
            return [
                ...this.workSlots,
                ...this.commercialSlots,
                ...this.nonBillFreeSlots,
                ...this.nonBillWorkSlots,
                this.holidaySlot,
                this.sickSlot,
                ...this.offRecoverySlots,
                ...this.offTrainingSlots
            ]
                .reduce((nb, slot) => nb + (slot.employeeIds.includes(employee.id) ? 1 : 0), 0);
        },
        nothingFor(employee) {
            return this.nbActivities(employee) === 0 && employee.isVisible;
        },
        employeeEnter(employeeId) {
            this.currentEmployee = employeeId;
        },
        employeeLeave(employeeId) {
            if (this.currentEmployee === employeeId) {
                this.currentEmployee = null;
            }
        },
        vehicleEnter(vehicleId) {
            this.currentVehicle = vehicleId;
        },
        vehicleLeave(vehicleId) {
            if (this.currentVehicle === vehicleId) {
                this.currentVehicle = null;
            }
        },
        isEmployeeOff(employee) {
            return [this.holidaySlot, this.sickSlot, ...this.offRecoverySlots, ...this.offTrainingSlots]
                .some(slot => slot.employeeIds.includes(employee.id));
        },
        slotToData(type, slot) {
            return {
                type: type,
                id: slot.id && !slot.id.toString().startsWith('_') ? slot.id : null,
                date: this.dateData,
                businessid: slot.businessId,
                detail: slot.detail,
                place: slot.place,
                contractor: slot.contractors,
                employees: slot.employeeIds,
                vehicles: slot.vehicleIds
            };
        }
    }
};
</script>
